<template>
<div>
  <div v-if="!more" class="fengmian-tit">
    <router-link :to="router" class="more">更多</router-link>
    <span>{{ title }}</span>
  </div>
  <div class=" bordernone">
    <ul class="list-3">
      <li
        v-for="(item, index) in data.list"
        :key="index"
        :data-index="index"
        :title="item.title"
        :class="[more && index % 5 == 4 ? `line` : ``]"
      >
        <router-link
          v-if="item.type == 1"
          :to="{
            path: item.router,
            query: { id: item.id },
          }"
          :title="item.title"
        >
          <div v-if="item.images==nil">
              <a class="title-t">{{ item.title }}</a>
              <div class="title-describes-tp">
                {{item.abstracts}}
            </div>
              <span style="display:block;margin-top: 10px;">{{ dateFormat(item.publishTime, "YYYY-MM-DD") }}</span>
            </div>
            <div v-if="item.images!==nil">
              <div class="img-p">
               <a><img 
              :src="item.images" alt="">
              </a>
            </div>
            <div style="display:flex; flex-direction:column; justify-content: space-between;">
              <div style="flex: 1;">
                <a class="title-p">{{ item.title }}</a>
                <div :class="['title-describes-p', expande ? 'expande' : 'close']" ref="title-describes-p">
                <!-- <div class="title-describes-p"> -->
                  {{item.abstracts}}</div>
                <div class="expande-button-wrap" v-if="needShowExpande">
                <div class="expande-button" @click="expandeClick" v-if="!expande">
                <span style="color: black">...</span> 展开
                </div>
                <div class="expande-button" @click="expandeClick" v-else>收起</div>
                </div>
              </div>
            </div>
              <span class="title-date" style="margin-left: 20px;">{{ dateFormat(item.publishTime, "YYYY-MM-DD") }}</span>
            </div>
            
            
        </router-link>
        <a v-else :href="item.content" target="_blank">
          <a class="title-t-p">{{`【转载】` + item.title}}</a>
            
            <div class="title-describes-t-p">
                {{item.abstracts }}
            </div>
              <span id="title-date-p" style="margin-left: 10px;">{{ dateFormat(item.publishTime, "YYYY-MM-DD") }}</span>
        </a>
      </li>
    </ul>
  </div>
  <div v-if="more" class="pages">
    <ul>
      <li>
        <span class="pageinfo"
          >共 <strong>{{ data.currentPage }}</strong
          >页<strong>{{ data.total }}</strong
          >条记录</span
        >
      </li>
    </ul>
  </div>
    </div>
</template>

<script>
import { toRefs } from "vue";
import dateFormat from "@/utils/dateFormat";
import {clamp} from "@/utils/clamp";

export default {
  name: "PanelList",
  components: {},
  props: ["title", "router", "more", "data"],
  data(){
    return {
            expande: true,
            needShowExpande: false,
    }
  },
  methods: {
        expandeClick() {
            console.log('expandeClick')
            this.expande = !this.expande
        },
    },
  setup(props) {
    return { dateFormat, ...toRefs(props) };
  },
   mounted() {
        this.$nextTick(() => {
            let lineHeight = 22
            if (this.$refs.content.offsetHeight > lineHeight * 3) {
                this.expande = false
                this.needShowExpande = true
            } else {
                this.expande = true
            }
        })
    },

};
// console.log(item)
</script>

<style scoped>
.title-describes-tp{
  display: block;
    width: 850px;
    height: 40px;
    line-height: 30px;
    display: block;
    float: left;
    color: #5b5b5b;
    word-wrap:break-word;
    white-space:normal;
}
</style>
