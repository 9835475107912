<template>
    <div class="panel">
        <div class="tit"> <span>最后更新</span> </div>
        <ul class="list-1">
            <li><a href="122.html"><span>07-22</span>市委统战部召开民主党派财务负责人座谈会</a></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "PanelLast",
        components: {},
        created() {
        },
        setup() {
        },
        methods: {},
    };
</script>

<style scoped>
</style>
